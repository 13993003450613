import React from "react";
import Paragraph from "../components/Paragraph";
import SubHeading from "../components/SubHeading";

const AboutContainer = () => {

    return (
        <section className="about bg-dark-100">
            <div className="container">
                <div className="row align-items-center">



                    <div className="col-sm-7">
                        <div className="about_text_inner">
                            <div className="about_text mt-3">
                                <SubHeading title="Grupo de improvisación 5INCO"></SubHeading>
                                <Paragraph text="Somos un grupo de improvisadores que te
                                                garantizará una experiencia inolvidable con
                                                su humor y alegría en la escena.">
                                </Paragraph>
                            </div>
                            <div className="btn_group pb-5">
                                <a href="/nosotros" className="btn gray">Ver más</a>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-5">
                        <div className="about_image">
                            <img className="img-fluid rounded mx-auto d-block" src="/images/about.jpg" alt="about"/>
                        </div>
                    </div>



                </div>
            </div>
        </section>
    );
};

export default AboutContainer
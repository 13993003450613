import React, {useEffect, useState} from "react";
import FunFact from '../components/FunFact';
import funFactsData from "../data/funFactsData.json"

const FunFacts = (props) => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
    
        const handleScroll = () => {
            const funfactsElement = document.getElementById('funfacts');
            const top = funfactsElement.getBoundingClientRect().top;
    
            if (!isVisible && top < window.innerHeight) {
                setIsVisible(true);
            };
        };
        window.addEventListener('scroll', handleScroll);
        
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isVisible]);
    
    useEffect(() => {
        if (isVisible) {
            startCounting();
        }
    }, [isVisible])
    
    const startCounting = () => {
        const funNumberElements = document.querySelectorAll('.fun-number');
    
        funNumberElements.forEach((element) => {
            const initialValue = 0;
            const finalValue = parseInt(element.textContent, 10);
    
            let currentCounter = initialValue;
    
            const updateCounter = () => {
                currentCounter += (finalValue - initialValue) / 500; 
                element.textContent = Math.ceil(currentCounter);
    
                if (currentCounter < finalValue) {
                    requestAnimationFrame(updateCounter);
                }
            };
            updateCounter();
        });
    }

    return(
        <section className={props.className} id='funfacts'>
            <div className='container'>
                <div className='row'>
                        <div className='funfacts_inner'>
                            {funFactsData.map(data =>
                                    <FunFact
                                        key={data.id}
                                        data={data}
                                    />)
                            }
                        </div>
                </div>
            </div>
        </section>
    );
};

export default FunFacts;


import React from "react";
import { useNavigate } from "react-router-dom"

const MemberCard = ({ data }) => {
    const { id, cartoon, details, name } = data;
    const navigate = useNavigate();

    const scrollToMember = (event) => {
        console.log("Navigating to /integrantes/:id", id);
        event.preventDefault();
        // navigate(`/integrantes#member-${id}`);
        navigate(`/integrantes/${id}`);
    };

    return (
        // <div className="col-md-4 grid-item" >
            <div className="thumb" onClick={scrollToMember}>
                <div className="grid-item">
                    <div className="thumb">
                        <img className="item_image" src={cartoon} alt="Cinco member" />
                        <div className="works-info">
                            <div className="label-text">
                                <h6>{name}</h6>
                                <h5>
                                    <p>{details}</p>
                                </h5>
                                <div className="details_link">
                                    {/* <a href={href}><span className="link_text">Ver más</span></a> */}
                                    <button className="link_text" onClick={scrollToMember}>
                                        Ver más
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        // </div>
    );
};
export default MemberCard;
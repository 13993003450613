import React from "react";
import MemberCard from "../components/MemberCard";
import SubHeading from "../components/SubHeading";
import membersData from "../data/membersData.json"

const Members = () => {

    return (
        <section className="projects packery bg-dark-100" >
            <div className="container">
                <div className="row">
                    <div className="about_text text-center">
                        <SubHeading title="Miembros"/>
                    </div>
                </div>
                <div className="row">
                    {/* <div className="swiper swiper_gallery_2"> */}
                        {membersData.map((data) => (
                            <div key={data.id} className="col-lg-1 col-md-4 col-sm-6 col-12" >
                                <MemberCard
                                    data={data} 
                                    />
                            </div>
                        ))}
                    {/* </div> */}
                </div>
            </div>
        </section>
    );
};
export default Members;

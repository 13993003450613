import React from "react";

const NewsCard = (props) => {

    return (
        <div className="col-lg-4 ">
            <div className="blog_post mb-0">

                <img src={props.img} alt="Novedades" />
                <div className="blog_content">

                    <h2 className="post-count">{props.postCount}</h2>
                    <div className="meta">
                        <time className="text-yellow" dateTime={props.dateTime}>
                            {props.date}
                        </time>
                    </div>
                    <h5>
                        <a
                            href="https://www.instagram.com/cincoimpro/"
                            target="_blank"
                            rel="noreferrer"
                            className="text-white"
                        >
                            {props.title}
                        </a>
                    </h5>
                    <p>{props.text}</p>
                    <div className="details_link">
                        <a
                            href="https://www.instagram.com/cincoimpro/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <span className="link_text">Conocer más</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default NewsCard

// const NewsCard = ({ media_type, thumbnail_url, caption, media_url, id, timestamp }) => {

//         <div className="col-lg-4 col-md-6">
//             <div className="blog_post mb-0">
//                 <a
//                     href="https://www.instagram.com/cincoimpro/"
//                     target="_blank"
//                     rel="noreferrer"
//                 >
//                     {media_type === "VIDEO" ? (
//                         // Video
//                         <div className="instagram_new">
//                             <img
//                                 src={thumbnail_url}
//                                 alt={caption}
//                                 className="insta-image"
//                                 controls
//                             />
//                         </div>
//                     ) : (
//                         // Image
//                         <div className="instagram_new">
//                             <img 
//                                 src={media_url} 
//                                 alt={caption} 
//                                 loading="lazy" 
//                                 className="insta-image" 
//                             />
//                         </div>
//                     )}
//                 </a>
//                 <div className="blog_content">
//                     <h2 className="post-count">{id}</h2>
//                     <div className="meta">
//                         <time className="text-yellow" dateTime={timestamp}>
//                             {timestamp}
//                         </time>
//                     </div>
//                     <h5>
//                         <a
//                             href="https://www.instagram.com/cincoimpro/"
//                             target="_blank"
//                             rel="noreferrer"
//                             className="text-white"
//                         >

//                         </a>
//                     </h5>
//                     <p>{caption}</p>
//                     <div className="details_link">
//                         <a
//                             href="https://www.instagram.com/cincoimpro/"
//                             target="_blank"
//                             rel="noreferrer"
//                         >
//                             <span className="link_text">Conocer más</span>
//                         </a>
//                     </div>
//                 </div>
//             </div>
//         </div>

//     );
// };

// export default NewsCard
import React from "react";

const SliderCard = ({ data }) => {
    const { bgImg } = data;

    return (
        <div className="carousel-item active">
            <img src={bgImg} className="d-block w-100" alt="grupo 5inco" />
        </div>
    );
};

export default SliderCard;
import React, { useEffect } from 'react';
import Isotope from 'isotope-layout';
import RelatedShowCard from "./RelatedShowCard";


const RelatedShows = (props) => {

    console.log(props.relatedShows)
    useEffect(() => {
        Isotope.current = new Isotope('.grid', {
            itemSelector: '.grid-item',
            percentPosition: true,
            transformsEnabled: true,
            transitionDuration: "700ms",
            resize: true,
            fitWidth: true,
            columnWidth: '.grid-sizer',
        })

        // cleanup
        return () => Isotope.current.destroy();

    }, []);

    return (
        <div className='related-posts text-white' id='related-posts' style={{paddingBottom:'200px'}}>
            <h6 className=''>Más Shows</h6>
            <div className='grid grid-1 gutter-15 clearfix '>
                <div className='grid-sizer'>
                    <div className='grid-item'>
                        {props.relatedShows.map((show) => (
                             <RelatedShowCard
                                key={show.id}
                                imgRelated={show.img}
                                hrefRelated={show.href}
                                nameRelated={show.name}
                        />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RelatedShows;
import React from "react";

const ShowCard = (props) => {
    return (
        <div className="thumb">
            <div className="row">
                <a href={props.href}>
                    <img className="item_image" src={props.img} alt="Show"/>
                </a>
            </div>
            <div className="row works-info">
                <div className="col-auto label-text" id="">
                    <h5><a href={props.href}>{props.details}</a></h5>
                </div>
            </div>
        </div>
    )
}

export default ShowCard;
import $ from 'jquery';
import React, { useEffect } from 'react';

const Footer = () => {

    useEffect(() => {
        //ToTop button
        $('.totop a').on('click', function (e) {
            e.preventDefault();
            $('html, body').animate({ scrollTop: 0 }, '300');
        });

        //Hide header on Scroll Down
        const nav = document.querySelector(".header");
        const scrollUp = "top-up";
        let lastScroll = 800;

        window.addEventListener("scroll", () => {
            const currentScroll = window.pageYOffset;
            if (currentScroll <= 800) {
                nav.classList.remove(scrollUp);
                $('.totop').removeClass('show');
                return;
            }

            if (currentScroll > lastScroll) {
                // down
                nav.classList.add(scrollUp);
                $('.totop').addClass('show');

            } else if (currentScroll < lastScroll) {
                // up
                nav.classList.remove(scrollUp);
                $('.totop').removeClass('show');
            }

            lastScroll = currentScroll;
        });

    }, []);

    return (
        <div>
            <footer className='footer bg-dark-200 box_padding'>
                <div className='footer_inner bg-black'>
                    <div className='container'>
                        <div className='row align-items-end'>
                            <div className='col-lg-4 col-md-2 col-sm-2'>
                            </div>
                            <div className='col-lg-4 col-md-5 col-sm-5'>
                                <div className='communication'>
                                    <div className='info_body'>
                                        <div className='info_body_elements'>
                                            <i className="bi bi-envelope"></i><h6>Email</h6>
                                        </div>
                                        <h5>5incoimpro@gmail.com</h5>
                                    </div>
                                    <div className='info_body'>
                                        <div className='info_body_elements'>
                                        <i className="bi bi-telephone"></i><h6>Llámanos</h6>
                                        </div>
                                        <h5><a href="tel:+573173099335">317 3099335</a></h5>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-5 col-sm-5'>
                                <div className='footer_elements d-flex align-items-center justify-content-end'>
                                    <div className='footer_elements_inner'>
                                        <div className='footer_logo'>
                                            <a href="/" className='light_logo'><img className='img-fluid' src="/images/logo-light.png" alt="logo" /></a>
                                        </div>
                                        <div className='footer_social'>
                                            <ul className='social_list'>
                                                <li className='instagram'>
                                                    <a href="https://www.instagram.com/cincoimpro/" target='blank'>
                                                        <i className="bi bi-instagram"></i>
                                                    </a>
                                                </li>
                                                <li className='facebook'>
                                                    <a href="https://www.facebook.com/5incoImpro" target='blank'>
                                                        <i className="bi bi-facebook"></i>
                                                    </a>
                                                </li>
                                                <li className='tiktok'>
                                                    <a href="https://tiktok.com/@5incoimpro" target='blank'>
                                                        <i className="bi bi-tiktok"></i>
                                                    </a>
                                                </li>
                                                <li className='Youtube'>
                                                    <a href="https://www.youtube.com/@5incoimpro789" target='blank'>
                                                        <i className="bi bi-youtube"></i>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className='copyright'>
                                            <p>5inco Impro 2024 | Todos los derechos reservados</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );

}

export default Footer;
import React from "react";
import NewsCard from "./NewsCard";
import SubHeading from "./SubHeading";

const NewsComponent = ({ instagramData = [] }) => {
    const sliceData = instagramData.slice(0, 3);
    // console.log("NewsComponent - instagramData:", instagramData);

    return(
        <section className="blog pt-lg bg-dark-100">
            <div className="container">
                <div className="about_text">
                    <SubHeading title="Novedades"></SubHeading>
                </div>

                <div className="row">
                    {sliceData.map((item, index) => (
                        <NewsCard
                            key={item.id}
                            img = {item.media_type === "VIDEO" ? item.thumbnail_url : item.media_url}
                            title=""
                            text={item.caption.length > 250 ? item.caption.slice(0, 275) + '...' : item.caption}
                            date={new Date(item.timestamp).toLocaleDateString('es-ES', {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric',
                            })
                            }
                            postCount={index + 1}
                        />
                    ))}
                </div>
            </div>
        </section>  
    );
};
export default NewsComponent;

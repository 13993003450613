import React from "react";

const SideShowDetails = (props) => {
    return(
        <div className="sidebar">
            <div className="project-information">
                <h6 className="widget-title">
                    {props.title}
                </h6>
                <ul>
                    <li>
                        <span className="value">{props.description}</span>
                    </li>
                    <li>
                        <span className="data">Público:</span> <span className="value">{props.audience}</span>
                    </li>
                    <li>
                        <span className="data">Duración:</span> <span className="value">{props.duration}</span>
                    </li>
                </ul>

            </div>

        </div>
    );
};

export default SideShowDetails;
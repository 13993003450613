import React from "react";
import ScrollToTopButton from "../components/ScrollToTop";
import Sliders from "../containers/Sliders";
import AboutContainer from "../containers/AboutContainer"
import FunFacts from "../containers/FunFacts";
import Members from "../containers/Members";
import NextShow from "../containers/NextShow";
import InstagramFeed from "../components/InstagramFeed";
// import FormContainer from "../containers/FormContainer";


const Home = () => {
    return (
        <div className="wraper">
            <ScrollToTopButton/>
            <Sliders/>
            <AboutContainer/>
            <FunFacts/>
            <Members/>
            <NextShow/>
            <InstagramFeed/>
            {/* <FormContainer/> */}
        </div>
    );
};

export default Home;
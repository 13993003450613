import React, { useState, useEffect } from "react";
import NewsComponent from "./NewsComponent";

const InstagramFeed = ({ onUpdate }) => {
  const [instagramData, setInstagramData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const accessToken = 'IGQWRPTHpmaW5XaElsdmZAyWDFQMjFYMHB4cl82MHFqUVp6MzZAlT3U4Rkw1STNTNjBxYW15ZAEVhTlh0YlpPS29leUJyY0YySHAxT201TDNpNk1FMlBnNF9MZA0lnQlFLTU1zVFkwd3R1Ui1iTW01WDhGRmdOdU5tQUUZD';
    const userId = '5628945457224139';
    let apiUrl = `https://graph.instagram.com/v18.0/${userId}/media?fields=id,caption,media_type,media_url,thumbnail_url,permalink,timestamp&access_token=${accessToken}`;

    const refreshAccessToken = async () => {
      try {
        // Use the 'refresh_access_token' endpoint to refresh the access token
        const refreshResponse = await fetch(`https://graph.instagram.com/refresh_access_token?grant_type=ig_refresh_token&&access_token=${accessToken}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (refreshResponse.ok) {
          const refreshedTokenData = await refreshResponse.json();
          const refreshedAccessToken = refreshedTokenData.access_token;

          // We are using the `apiUrl` refactored with the refreshed access token, to ensure that subsequent API calls use the refreshed access token rather than the initially hardcoded one
          //const refreshedApiUrl = `https://graph.instagram.com/v18.0/${userId}/media?fields=id,caption,media_type,media_url,thumbnail_url,permalink,timestamp&access_token=${refreshedAccessToken}`; We can't use this one in the second refreshed, because we need dynamically generate the apiUrl based on the current access token, especially after refreshing.
          apiUrl = `https://graph.instagram.com/v18.0/${userId}/media?fields=id,caption,media_type,media_url,thumbnail_url,permalink,timestamp&access_token=${refreshedAccessToken}`;


          const response = await fetch(apiUrl, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          });

          if (response.ok) {
            const refreshedInstagramData = await response.json();
            setInstagramData(refreshedInstagramData.data);
            setLoading(false);
          } else {
            console.log('Error getting Instagram data after token refresh');
          }
        } else {
          console.log('Error refreshing access token');
        }
      } catch (error) {
        console.error('Error refreshing access token', error);
      }
    };

    const getInstagramData = async () => {
      try {
        const response = await fetch(apiUrl, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        });

        if (response.ok) {
          const instagramData = await response.json();
          // console.log("InstagramFeed - instagramData:", instagramData);

          setInstagramData(instagramData.data);
          setLoading(false);
        } else {
          console.log('Error getting Instagram data')
        }
      } catch (error) {
        console.error('Error getting Instagram data', error);
      }
    };

    // Check if the access token is about to expire and refresh it if needed
    const expirationThreshold = 24 * 60 * 60 * 1000; // 24 hours in milliseconds. To check if the access token isn't expired
    const expirationDate = new Date('March 10, 2024'); // Assuming 'expires_in' is provided by the Instagram API
    // Use the following `expirationDate` const when we need to refresh the token for a second time, when the response brings us the `expires_in` property in the first refreshing:
    //const expirationDate = new Date((accessToken.expires_in + Date.now() / 1000) * 1000); // Assuming 'expires_in' is provided by the Instagram API - This code calculates the expiration date based on the expires_in value obtained during the refresh.

    if (expirationDate - Date.now() < expirationThreshold) {
      refreshAccessToken();
    } else {
      getInstagramData();
    }
  }, []);

  return (
    <div>
      {loading ? (
        <p>Cargando...</p>
      ) : (
        <NewsComponent instagramData={instagramData} />
      )}
    </div>
  )
};

export default InstagramFeed;



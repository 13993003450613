import React from "react";

const FunFact = ({ data }) => {
    const { contentName, contentNumber, plus, img } = data;

    return(
            <div className="col funfact2 d-flex align-items-center pb-4">
                <div className="fun_img">
                    <img src={img} alt="funfact" />
                </div>
                <div className="funfact_content">
                    <p>{contentName}</p>
                    <div className="d-flex align-items-center">
                        <h2 className="fun-number">{contentNumber}</h2><span>{plus}</span>
                    </div>
                </div>
            </div>
    );
};

export default FunFact;
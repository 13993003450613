import React from "react";


const RelatedShowCard = (props) => {
    return(
        <div className="thumb">
            <div className="col-lg-8 col-md-4">
                <a href={props.hrefRelated}>
                    <img className="item_image" id="relatedshow_img" src={props.imgRelated} alt="img"/>
                </a>
                <div className="works-info">
                    <div className="label-text">
                        <h5>
                            <a href={props.hrefRelated}>{props.nameRelated}</a>
                        </h5>
                    </div>
                </div>
            </div>
        </div>
        
    );
};

export default RelatedShowCard;

import React, { useEffect, useState } from 'react';

const UpperHeader = () => {
    // Hide header on scroll down
    window.onscroll = function () { myFunction() };
    function myFunction() {
        if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
            document.querySelector('header .container').classList.add('.top-up');
        } else {
            document.querySelector('header .container').classList.remove('.top-up');
        }
    }

    const handleScroll = () => {
        const scroll = window.scrollY;
        const logo = document.querySelector('.header .logo a.dark_logo');
        const hideItems = document.querySelectorAll('.header .mainnav ul.main_menu img.hide-initial');
        const hideItemsSpan = document.querySelectorAll('.header .mainnav ul.main_menu span.hide-initial');
        const scaleFactor = Math.max(1 - scroll / 200, 0.5);

        // Scale the logo
        logo.style.transform = `scale(${scaleFactor})`;
        

        if (scroll >= 50) {
            hideItems.forEach((item) => {
                item.style.opacity = 1;
            });
            hideItemsSpan.forEach((item) => {
                item.style.opacity = 0;
            });
        } else {
            hideItems.forEach((item) => {
                item.style.opacity = 0;
            });
            hideItemsSpan.forEach((item) => {
                item.style.opacity = 1;
            });
        }
    };

    window.addEventListener('scroll', handleScroll);


    useEffect(() => {
        // Stick header
        const header = document.querySelector("header");

        const handlescroll = () => {
            const scroll = window.scrollY;

            if (scroll >= 50) {
                header.classList.add("sticky");
            } else {
                header.classList.remove("sticky");
            }
        };
        window.addEventListener('scroll', handlescroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    return (
        <header className="header bg-black">
            <div className='container'>
                <div className='header_inner d-flex align-items-center justify-content-between'>
                    <div className='logo'>
                        <a href="/" className='dark_logo'><img src="/images/logo-dark.png" alt="logo" /></a>
                    </div>

                    <div className='mainnav collapse d-lg-block' id='mainMenu'>
                        <ul className='main_menu'>
                            <li className='menu-item'><a href="/shows"><img src="/images/logo_5.png" className='logo-item hide-initial' alt="shows" /><span className='hide-initial'>5</span>hows</a></li>
                            <li className='menu-item'><a href="/integrantes"><img src="/images/logo_i.png" className='logo-item hide-initial' alt="Integrantes" /><span className='hide-initial'>I</span>ntegrantes</a></li>
                            <li className='menu-item'><a href="/nosotros"><img src="/images/logo_n.png" className='logo-item hide-initial' alt="Nosotros" /><span className='hide-initial'>N</span>osotros</a></li>
                            <li className='menu-item'><a href="/comedia"><img src="/images/logo_c.png" className='logo-item hide-initial' alt="Comedia" /><span className='hide-initial'>C</span>omedia</a></li>
                            <li className='menu-item'><a href="/oferta"><img src="/images/logo_o.png" className='logo-item hide-initial' alt="Oferta" /><span className='hide-initial'>O</span>ferta</a></li>
                        </ul>
                    </div>
                    <div className="d-flex align-items-center">
                        {/* Mobile Responsive Menu Toggle Button */}
                        <button type='button' className='mr_menu_toggle d-lg-none' onClick={() => setIsMenuOpen(!isMenuOpen)}>
                            <i className='bi bi-list'></i>
                        </button>
                    </div>
                </div>
            </div>
            {/* Conditional rendering for mobile menu */}
            {isMenuOpen && (
                <div className="container">
                    <div className="mainnav d-lg-none">
                        <ul className='main_menu'>
                            <li className='menu-item'><a href="/shows">Shows</a></li>
                            <li className='menu-item'><a href="/integrantes">Integrantes</a></li>
                            <li className='menu-item'><a href="/nosotros">Nosotros</a></li>
                            <li className='menu-item'><a href="/comedia">Comedia</a></li>
                            <li className='menu-item'><a href="/oferta">Oferta</a></li>
                        </ul>
                    </div>
                </div>
            )}
        </header>
    );
};
export default UpperHeader;
import React from 'react';
// import MobileResponsiveMenu from './MobileResponsiveMenu';
import UpperHeader from './UpperHeader';

const Header = () => {
    return (
        <div>
            <UpperHeader/>
        </div>
    )
}

export default Header;
import React from "react";

const Video = () => {
    return (
        <div className="container p-lg-5">
            <div className="video_post">
                <div className="ytube_video">
                <iframe width="100" height="200" src="https://www.youtube.com/embed/OnZIFbO_UTY" title="El Ascensor" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    <div className="post_content">
                        {/* <div className="ytplay_btn">
                            <i className="bi bi-play-fill"></i>
                        </div> */}
                        {/* <img src="images/bg/video_bg.jpg" alt="video" /> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Video;
import React from "react";
import SideShowDetails from "../components/SideShowDetails";
import RelatedShows from "./RelatedShows";


const ShowContainer = (props) => {
    return(
        <section className="project-details bg-dark-200">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-6 ">
                        <div className="post-image">
                            <img src={props.img} alt={props.alt} />
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <SideShowDetails
                            title={props.title}
                            description={props.description}
                            audience={props.audience}
                            duration={props.duration}
                        />
                    </div>
                </div>

                <RelatedShows relatedShows={props.relatedShows} />
            </div>
        </section>
    );
};

export default ShowContainer;
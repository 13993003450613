import React from "react";

const OfferCard = ({ data }) => {
    const { date, show, place } = data;

    // converts the date string into a JavaScript Date object, which allows us to perform various operations with dates and times.
    const eventDate = new Date(date);
    const options = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    };
    const formattedDate = eventDate.toLocaleDateString('es-ES', options);

    return (
        <div className="post_content pb-4">
            <div className="edu-quali">
                <ul className="point_order text-white">
                    <li>Show: {show}</li>
                    <li>Fecha: {formattedDate}</li>
                    <li>Lugar: {place}</li>

                </ul>

            </div>
        </div>
    );
};
export default OfferCard;
import React from 'react';

const ScrollToTopButton = () => {
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <button onClick={scrollToTop} className="scroll-to-top">
            <i className="bi bi-arrow-up"></i>
        </button>
    );
};

export default ScrollToTopButton;
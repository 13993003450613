import React, { useLayoutEffect, useRef, useState } from "react";

const GalleryCard = ({ data }) => {
    const targetRef = useRef();
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

    useLayoutEffect(() => {
        if (targetRef.current) {
            setDimensions({
                width: targetRef.current.offsetWidth,
                height: targetRef.current.offsetHeight
            });
        }
    }, []);

    const { img, tag } = data;

    const minHeight = 500;
    const mediumHeight = 680;
    const largeHeight = 810;

    const calculateHeight = () => {
        const { width } = dimensions;
        if (width <= 600) {
            return minHeight;
        } else if (width <= 1550) {
            return mediumHeight;
        } else if (width <= 2000) {
            return largeHeight;
        } else {
            return mediumHeight; // Fallback height for larger screens
        }
    };

    return (
        <div className={`grid-item ${tag} width-50`}>
            <div className="thumb" ref={targetRef}>
                <img
                    className="item_image"
                    // id="gallery_image" 
                    src={img}
                    alt="Show"
                    style={{ height: `${calculateHeight()}px`, width: "100%", transition: 'transform 0.5s ease-in-out' }}
                />
            </div>
        </div>
    );
};
export default GalleryCard;
import React from "react";
import ScrollToTopButton from "../components/ScrollToTop";
import ShowCard from "../components/ShowCard";

const Shows = () => {
    return (
        <section className="projects effect-tilt bg-dark-100" >
            <ScrollToTopButton/>
            <div className="container align-items-center">
                <div className="row clearfix mb-clearfix" id="show_posters">
                    <div className="row">
                        <div className="grid-item" id="show_poster">
                            <ShowCard 
                                href="/impro-show" 
                                img="images/shows/5impro-show.png" 
                                details="5INCO IMPRO SHOW"
                            />
                        </div>
                        <div className="grid-item" id="show_poster">
                            <ShowCard 
                                href="/5-minutos-fama" 
                                img="images/shows/5minutos-fama.png" 
                                details="5INCO MINUTOS DE FAMA"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="grid-item" id="show_poster">
                            <ShowCard 
                                href="/impro-plus" 
                                img="images/shows/5impro-plus.png" 
                                details="5INCO IMPRO PLUS"
                            />
                        </div>
                        <div className="grid-item" id="show_poster">
                            <ShowCard 
                                href="/5incomediantes" 
                                img="images/shows/5mediantes.png" 
                                details="5INCO MEDIANTES"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Shows;
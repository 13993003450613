import React, { useEffect } from "react";
import ScrollToTopButton from "../components/ScrollToTop";
import membersData from "../data/membersData.json";
import { useParams } from "react-router-dom";

const TeamDetailsContainer = () => {
    const { id } = useParams();
    
    useEffect(() => {
        console.log("Received id:", id);

        if (id) {
            const element = document.getElementById(`member-${id}`);
            if (element) {
                const headerHeight = document.querySelector("header").offsetHeight;
                const offset = headerHeight + 10;
                // element.scrollIntoView({ behavior: "smooth" });
                window.scrollTo({
                    top: element.offsetTop - offset,
                    behavior: "smooth"
                });
            }
        }
    }, [id]);

    return (
        <main className="wrapper">
            <section className="team team-details bg-dark-200">
            <ScrollToTopButton/>
                    {membersData.map((member) => (
                        <div key={member.id} id={`member-${member.id}`} className="container pb-5 pt-5">

                            <div className="row">
                                <div className="col-lg-7 col-md-7 pe-lg-2">
                                    <div className="team-member-img">
                                        <img src={member.img} alt="img" />
                                    </div>
                                </div>
                                <div className="col-lg-5 col-md-5 ps-lg-0">
                                    <div className="team-member-information">
                                        <h5 className="name text-white">{member.name}</h5>
                                        <h6 className="designation text-uppercase text-gray-600">{member.occupation}</h6>
                                        <div className="bio text-gray-600">
                                            {member.description}
                                        </div>
                                        <div className="social-media">
                                            <ul>
                                                <li><a href={member.media.instagram} target="_blank" rel="noreferrer"><i className="bi bi-instagram"></i></a></li>
                                                <li><a href={member.media.facebook} target="_blank" rel="noreferrer"><i className="bi bi-facebook"></i></a></li>
                                                {/* <li><a href={member.media.x} target="_blank" rel="noreferrer"><i className="bi bi-twitter-x"></i></a></li> */}
                                                <li><a href={member.media.youtube} target="_blank" rel="noreferrer"><i className="bi bi-youtube"></i></a></li>
                                                {/* <li><a href={member.media.tiktok} target="_blank" rel="noreferrer"><i className="bi bi-tiktok"></i></a></li> */}
                                            </ul>
                                        </div>
                                        <div className="edu-quali">
                                            <ul className="point_order">
                                                <li>{member.achievements.li1}</li>
                                                <li>{member.achievements.li2}</li>
                                                {/* <li>{member.achievements.li3}</li> */}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
            </section>
        </main>
    );
};
export default TeamDetailsContainer;
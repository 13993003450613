import React from "react";
import ScrollToTopButton from "../components/ScrollToTop";
import MissionContainer from "../containers/MissionContainer";
import Video from "../components/Video";

const AboutUs = () => {
    return (
        <main className="wrapper bg-dark-100">
            <ScrollToTopButton/>
            <MissionContainer/>
            <Video/>
        </main>
    );
};

export default AboutUs;
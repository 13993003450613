import React from "react";
// import { Swiper, SwiperSlide } from "swiper/react";
// import { EffectFade, Navigation, Pagination } from 'swiper/modules';
import SliderCard from '../components/SliderCard';
import cincoImagesSliderData from "../data/cincoImagesSliderData.json"


const Sliders = () => {

    return (
        <div className="theme_slider bg-black" style={{paddingTop: '150px'}}>
            <div className="container">
                <div id="swiper_theme_slider" className="carousel slide carousel-fade " data-bs-ride="carousel">
                    {/* There is an issue, because at first it only displays 1 image. After a while the carrousel starts */}
                    {/* <div className="carousel-indicators">
                        <button type="button" data-bs-target="#swiper_theme_slider" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#swiper_theme_slider" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#swiper_theme_slider" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    </div> */}
                    <div className="carousel-inner">
                    {cincoImagesSliderData.map((data) => (
                        <SliderCard
                            key={data.id}
                            data={data}
                        />
                        ))}
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#swiper_theme_slider" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#swiper_theme_slider" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>


                </div>
                {/* <div className="swiper swiper_theme_slider" data-swiper-autoplay="2000">
                    <Swiper
                        modules={[Pagination, EffectFade, Navigation]}
                        effect="slide"
                        slidesPerView={1}
                        loop={true}
                        pagination={{
                            clickable: true,
                            dynamicBullets: true,
                        }}
                        navigation={{
                            prevEl: '.swiper-button-prev',
                            nextEl: '.swiper-button-next',
                        }}
                        autoplay={{ delay: 5000 }}
                    >
                        {
                            cincoImagesSliderData.map(data =>
                                <SwiperSlide key={data.id}>
                                    <SliderCard
                                        data={data}
                                    />
                                </SwiperSlide>)
                        }
                    </Swiper>

                    <div className="swiper-navigation">
                        <div className="swiper-button-prev"><i className="bi bi-arrow-left"></i></div>
                        <div className="swiper-button-next"><i className="bi bi-arrow-right"></i></div>
                    </div>
                </div> */}
            </div>
        </div>
    );
};

export default Sliders;
import React from "react";

const MissionContainer = () => {
    return(
        <section className="mission bg-dark-100">
            <div className="container">
                <div className="row mission_top_part">
                    <div className="col section-header text-center">
                        <h6 className="text-white text-uppercase">Acerca de Nosotros</h6>
                        <p className="text-gray-600">Mediante este arte buscamos transmitir sensaciones en los espectadores 
                                                    que les permitan desligarse de la cotidianidad, reflexionar, entretenerse 
                                                    y amplíen su percepción sobre la creatividad. Nosotros disfrutamos el proceso, 
                                                    y nos preocupamos por realizar nuestros espectáculos, eventos y presentaciones 
                                                    con un alto contenido estético y artístico.</p>
                    </div>

                    <div className="row has_line">
                        <img className="about-us-img" src="images/about-us-1.jpeg" alt="Grupo de improvisación 5inco" />
                    </div>

                    <div className="mission_bottom_part">
                        <div className="row justify-content-center">
                            <div className="col-lg-4 pe-lg-4">
                                <div className="section-header">
                                    <h3 className="text-white text-uppercase border-line">
                                        Somos un grupo de improvisación de la ciudad de Medellín desde hace 6 años.
                                    </h3>
                                </div>
                            </div>
                            <div className="col-lg-6 ps-lg-4">
                                <div className="mission_content">
                                    <p>Nacimos a partir de la idea de unos amigos que se comprometieron con el estudio de la improvisación teatral y la profundización de su técnica.</p>
                                    <p>Cada integrante del grupo tiene diferentes experiencias en otros campos tales como stand up, música, cuentería, teatro dramático, danza y trova paisa, lo que le da a 5inco un toque único y característico.</p>
                                    <p>Contamos con la fortuna de haber pisado las tablas de teatros de gran tradición en la ciudad de Medellín como el Teatro Popular de Medellín (TPM), Teatro Porfirio Barba Jacob, Teatro Prado 'Águila descalza', Teatro Pablo Tobón Uribe, Acción Impro, entre otros.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default MissionContainer;
import React from "react";
import Paragraph from "../components/Paragraph";
import SubHeading from "../components/SubHeading";
import nextShowData from "../data/nextShowData.json"

const NextShow = () => {

    // const { img, link_pago } = data;

    return (
        <section className="about bg-dark-200">
            <div className="container">
                {nextShowData.map((data) => (
                    <div key={data.id}>
                        <div className="row align-items-center">
                            <div className="col-sm-5">
                                <div className="show_image">
                                    <img className="img-fluid" src={data.img} alt="próximo show"/>
                                </div>
                            </div>
                            <div className="col-sm-7">
                                <div className="about_text_inner">
                                    <div className="about_text mt-3">
                                        <SubHeading title="Próximo Show"></SubHeading>
                                        <Paragraph text="Ven y acompáñanos en una noche llena de diversión y comedia!">
                                        </Paragraph>
                                    </div>
                                    <div className="btn_group">
                                        <a className="btn gray" href={data.link_pago}  target="_blank" rel="noreferrer">Adquirir boleta</a>
                                    </div>
                                    <div className="details_link_nextshow">
                                        <a className="link_text" href="/oferta" >Ver próximos eventos</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}

            </div>
        </section>
    );
};

export default NextShow;
import { createBrowserRouter } from "react-router-dom";
import HomeLayout from "../layouts/HomeLayout";

import Home from "../pages/Home";
import AboutUs from "../pages/AboutUs";
import Shows from "../containers/Shows";
import ImproShow from "../pages/ShowPages/ImproShow";
import Cincomediantes from "../pages/ShowPages/Cincomediantes";
import MinutosFama from "../pages/ShowPages/MinutosFama";
import ImproPlus from "../pages/ShowPages/ImproPlus";
import TeamDetailsContainer from "../containers/TeamDetailsContainer";
import CincoGallery from "../containers/CincoGallery";
import OfferContainer from "../containers/OfferContainer";


export const routes = createBrowserRouter ([
    {
        path: "/",
        element: <HomeLayout/>,
        children: [
            {
                path: "/",
                element: <Home/>,
            },
            {
                path: "/shows",
                element: <Shows/>,
            },
            {
                path: "/impro-show",
                element: <ImproShow/>,
            },
            {
                path: "/5-minutos-fama",
                element: <MinutosFama/>,
            },
            {
                path: "/impro-plus",
                element: <ImproPlus/>,
            },
            {
                path: "/5incomediantes",
                element: <Cincomediantes/>,
            },
            
            {
                path: '/nosotros',
                element: <AboutUs/>,
            },
            {
                path: '/integrantes',
                element: <TeamDetailsContainer/>,
            },
            {
                path: '/integrantes/:id',
                element: <TeamDetailsContainer/>,
            },
            {
                path: '/comedia',
                element: <CincoGallery/>,
            },
            {
                path: '/oferta',
                element: <OfferContainer/>,
            },
        ]
    }
]);
